// js
AOS.init();

$(document).ready(function() {

  $(".hamburger").click(function() {
    $(this).toggleClass("is-active");
    $(".box-menu").toggleClass("open");
    $("header").toggleClass("open-menu");
    $("html").toggleClass("hidden-overflow");

  });

  // $(".btn-lang").click(function(e) {
  //   $(this).toggleClass("show");
  //   e.stopPropagation();
  // });

  // $(document).click(function(e) {
  //   if ($(e.target).is(".btn-lang") === false) {
  //     $(".btn-lang").removeClass("show");
  //   }
  // });



  // Add smooth scrolling to all links
  $("a").on('click', function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();
      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 1000, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        // window.location.hash = hash;
      });
    } // End if
  });


  // if ($('.go-to-top').length) {
  //   var scrollTrigger = 1000, // px
  //   backToTop = function () {
  //     var scrollTop = $(window).scrollTop();
  //   };
  //   backToTop();
  //   $(window).on('scroll', function () {
  //     backToTop();
  //   });
  //   $('.go-to-top').on('click', function (e) {
  //     e.preventDefault();
  //     $('html,body').animate({
  //       scrollTop: 0
  //     }, 700);
  //   });
  // }


  // $(window).scroll(function() {
  //   var scroll = $(window).scrollTop();
  //
  //   if (scroll >= 200) {
  //     $(".go-to-top").addClass("show");
  //   } else {
  //     $(".go-to-top").removeClass("show");
  //   }
  // });

  // if( $('.number-counter').length) {
  //   $('.number-counter').counterUp({
  //     delay: 10,
  //     time: 2000
  //   });
  // }


});
